import React from 'react'
import "../Assets/Styles/WelcomePage.css"

const WelcomePage = () => {
  return (
    <>
    <div className='box'>
        <h3>Tekisky Mart <br />  DashBoard</h3>
    </div>
    </>
  )
}

export default WelcomePage